window.dataLayer = window.window.dataLayer || [];

var EE = {
	addImpression: function (products, list) {
		window.dataLayer.push({
			event: "impression",
			ecommerce: {
				impressions: products,
			},
		});
	},

	viewDetail: function (products, list) {
		window.dataLayer.push({
			event: "detail",
			ecommerce: {
				detail: {
					actionField: {
						list: list,
					},
					products: products,
				},
			},
		});
	},

	addToCart: function (products, list) {
		window.dataLayer.push({
			event: "addToCart",
			ecommerce: {
				add: {
					actionField: {
						list: list,
					},
					products: products,
				},
			},
		});
	},

	removeFromCart: function (products, list) {
		window.dataLayer.push({
			event: "removeFromCart",
			ecommerce: {
				remove: {
					actionField: {
						list: list,
					},
					products: products,
				},
			},
		});
	},

	onCheckout: function (step, products, checkoutOption) {
		window.dataLayer.push({
			event: "checkout",
			ecommerce: {
				checkout: {
					actionField: { step: step, option: checkoutOption },
					products: products,
				},
			},
		});
	},

	onCheckoutOption: function (step, checkoutOption) {
		window.dataLayer.push({
			event: "checkoutOption",
			ecommerce: {
				checkout_option: {
					actionField: { step: step, option: checkoutOption },
				},
			},
		});
	},

	onPurchase: function (order, products) {
		window.dataLayer.push({
			event: "purchase",
			ecommerce: {
				purchase: {
					transaction_id: order.tid,
					value: order.revenue,
					tax: order.tax,
					shipping: order.shipping,
					currency: "USD",
					items: products,
				},
			},
		});
	},

	getOrder: function () {
		var $container = $('[data-eec-page="confirmation"]');
		var tid = $container.find("[data-eec-tid]").data("eec-tid");
		var revenue = $container.find("[data-eec-revenue]").data("eec-revenue");
		var tax = $container.find("[data-eec-tax]").data("eec-tax");
		var shipping = $container.find("[data-eec-shipping]").data("eec-shipping");

		var order = {
			tid: tid,
			revenue: revenue,
			currency: "USD",
			tax: tax,
			shipping: shipping,
		};

		return order;
	},

	getProducts: function ($el, _impression) {
		var $product = $el || $(".product-result");
		var products = [];
		var impression = _impression || false;

		$product.each(function (i, e) {
			var $this = $(e);
			var name = $this.find("[data-eec-name]").data("eec-name");
			var id = $this.find("[data-eec-sku]").data("eec-sku");
			var price = $this.find("[data-eec-price]").data("eec-price");
			var quantity = $this.find("[data-eec-quantity]").val()
				? $this.find("[data-eec-quantity]").val()
				: $this.find("[data-eec-quantity]").data("eec-quantity");
			var brand = $this.find("[data-eec-brand]").data("eec-brand");
			var category1 = $this.find("[data-eec-category1]").data("eec-category1");
			var category2 = $this.find("[data-eec-category2]").data("eec-category2");
			var category = category1 + "/" + category2;

			if (!impression) {
				products.push({
					id: id,
					name: name,
					price: price,
					currency: "USD",
					quantity: quantity,
					brand: brand,
					category: category,
				});
			} else {
				products.push({
					id: id,
					name: name,
					brand: brand,
					category: category,
					list: getList(),
					position: i + 1,
				});
			}
		});

		return products;
	},
};

var DR = {
	viewItem: function (value, items) {
		window.dataLayer.push({
			event: "view_item",
			value: value,
			items: items,
		});
	},

	addToCart: function (value, items) {
		window.dataLayer.push({
			event: "add_to_cart",
			value: value,
			items: items,
		});
	},

	onPurchase: function (value, items) {
		window.dataLayer.push({
			event: "purchase",
			value: value,
			currency: "USD",
			items: items,
		});
	},

	getProducts: function ($el) {
		var $product = $el || $(".product-result");
		var products = [];
		var value = $product.first().find("[data-eec-price]").data("eec-price");

		$product.each(function (i, e) {
			var $this = $(e);
			var id = $this.find("[data-eec-sku]").data("eec-sku");

			products.push({
				id: id,
				google_business_vertical: "retail",
			});
		});

		return { value: value, items: products };
	},
};

$(document).on("click", ".add-to-cart .add", function (e) {
	var $product = $(this).parents(".product-result");
	var name = $product.find("[data-eec-name]").data("eec-name");
	var id = $product.find("[data-eec-sku]").data("eec-sku");
	var price = $product.find("[data-eec-price]").data("eec-price");
	var quantity = $product.find("[data-eec-quantity]").val();
	var brand = $product.find("[data-eec-brand]").data("eec-brand");
	var category1 = $product.find("[data-eec-category1]").data("eec-category1");
	var category2 = $product.find("[data-eec-category2]").data("eec-category2");
	var category = category1 + "/" + category2;

	var eProduct = {
		id: id,
		name: name,
		price: price,
		currency: "USD",
		quantity: quantity,
		brand: brand,
		category: category,
	};

	var dProduct = {
		id: id,
		google_business_vertical: "retail",
	};

	EE.addToCart([eProduct], getList());
	DR.addToCart(price, [dProduct]);
});

$(document).on("click", ".add-to-cart .update", function (e) {
	var $product = $(this).parents(".product-result");
	var name = $product.find("[data-eec-name]").data("eec-name");
	var id = $product.find("[data-eec-sku]").data("eec-sku");
	var price = $product.find("[data-eec-price]").data("eec-price");
	var quantity = $product.find("[data-eec-quantity]").val();
	var brand = $product.find("[data-eec-brand]").data("eec-brand");
	var category1 = $product.find("[data-eec-category1]").data("eec-category1");
	var category2 = $product.find("[data-eec-category2]").data("eec-category2");
	var category = category1 + "/" + category2;

	var product = {
		id: id,
		name: name,
		price: price,
		currency: "USD",
		quantity: quantity,
		brand: brand,
		category: category,
	};

	if (quantity > 0) {
		EE.addToCart([product], getList());
	} else {
		EE.removeFromCart([product], getList());
	}
});

$(document).on("click", ".clear-cart", function (e) {
	var products = EE.getProducts();
	EE.removeFromCart(products, getList());
});

$(document).on("click", ".apply-shipping", function (e) {
	var shipping = $("[data-eec-shipping]")
		.children("option:selected")
		.text()
		.trim();
	var products = EE.getProducts($(".product"));
	if (products.length) {
		EE.onCheckout(3, products, shipping);
	}
});

$(document).on("click", ".checkout", function (e) {
	var gateway = $('[name="gateway"]').children("option:selected").text().trim();
	var cardType = $('[name="card_type"]')
		.children("option:selected")
		.text()
		.trim();
	payment = gateway == "Offline Payment" ? gateway : cardType;
	var products = EE.getProducts($(".product"));
	if (products.length) {
		EE.onCheckout(4, products, payment);
	}
});

$(document).on("change", "[data-eec-shipping]", function (e) {
	var shipping = $(this).children("option:selected").text().trim();
	EE.onCheckoutOption(3, shipping);
});

$(document).on("change", '[name="gateway"]', function (e) {
	var gateway = $(this).children("option:selected").text().trim();
	EE.onCheckoutOption(4, gateway);
});

$(document).on("change", '[name="card_type"]', function (e) {
	var cardType = $(this).children("option:selected").text().trim();
	EE.onCheckoutOption(4, cardType);
});

function getPage() {
	return $("[data-eec-page]").data("eec-page");
}

function getList() {
	return $("[data-eec-list]").data("eec-list");
}

function init() {
	var page = getPage();
	var list = getList();

	if (page == "impression") {
		var eProducts = EE.getProducts(null, true);
		EE.addImpression(eProducts, list);
	}

	if (page == "detail") {
		var eProducts = EE.getProducts();
		EE.viewDetail(eProducts, list);
	}

	if (page == "cart") {
		var products = EE.getProducts();
		if (products.length) {
			EE.onCheckout(1, products);
		}
	}

	if (page == "account") {
		var products = EE.getProducts($(".product"));
		if (products.length) {
			EE.onCheckout(2, products);
		}
	}

	if (page == "shipping") {
		var products = EE.getProducts($(".product"));
		if (products.length) {
			EE.onCheckout(3, products);
		}
	}

	if (page == "payment") {
		var products = EE.getProducts($(".product"));
		if (products.length) {
			EE.onCheckout(4, products);
		}
	}

	if (page == "confirmation") {
		var order = EE.getOrder();
		var eProducts = EE.getProducts($(".product"));
		if (order) {
			EE.onPurchase(order, eProducts);
		}
	}
}

init();
